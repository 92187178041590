import moment from "moment";
import type { MomentInput } from "moment";
import qs from "query-string";

import { store as reduxStore } from "../redux/store/configureStore";

import { type NavigateFunction } from "react-router-dom";

import {
	Headers,
	QueryFilters,
	RequestConfig,
	RequestOptions,
	ResponseWithPaging,
	QueryParams,
	ExtendedFilters
} from "../types";

import { testConsts, queryKeys, sorting, sortFields } from "./const";
import { validDateFilter } from "./dateTime";
import { arrayFind, checkID, objectValues } from "./method";

const getOffset = (limit: number, page: number): number => limit * (page - 1);

const createPaginationQuery = (limit: number, page: number): string => {
	let query = `?limit=${limit}`;
	if (page > 1) {
		query += `&offset=${getOffset(limit, page)}`;
	}

	return query;
};

const createQuery = (
	...params: { [key: string]: string | number | boolean }[]
): string => {
	let query = "";

	params.forEach(param => {
		const { key, value } = param;

		if (typeof value !== "boolean" && !value) return;

		const prefix = query === "" ? "?" : "&";
		query += `${prefix}${key}=${encodeURIComponent(value)}`;
	});

	return query;
};

const createRoute = (
	safeID: boolean,
	...paths: (string | number)[]
): string => {
	let requestPath = "";

	paths.forEach((path, i) => {
		if (!path) return;

		let safePath = path;

		if (safeID && i % 2 > 0) {
			safePath = checkID(safePath as string);
		}

		if (
			String(safePath).slice(0, 1) !== "/" &&
			requestPath.slice(-1) !== "/" &&
			String(safePath).slice(0, 1) !== "#"
		) {
			requestPath += "/";
		}

		requestPath += safePath;
	});

	requestPath += "/";

	return requestPath;
};

const validateLimit = (limit: string | (string | null)[] | null): string => {
	if (typeof limit !== "string" || !/^\d+$/.test(limit)) return "20";

	switch (limit) {
		case "10":
			return "10";
		case "50":
			return "50";
		case "100":
			return "100";
		default:
			return "20";
	}
};

const validatePage = (page: string | (string | null)[] | null): string =>
	typeof page !== "string" || !/^\d+$/.test(page) ? "1" : page;

const getResultQueryParams = (searchQuery: string): ExtendedFilters => {
	const queryStrings = qs.parse(searchQuery);
	const {
		label,
		from,
		limit,
		mode,
		order_by,
		page,
		search,
		to,
		filter_email,
		filter_member_role
	} = queryStrings;
	const { MODE } = testConsts;

	const validMode = arrayFind(objectValues(MODE) as [], "key", mode);
	const validOrder = Object.values(sortFields).find(key => {
		if (typeof order_by !== "string") return false;

		if (order_by[0] === "-") return key === order_by.substring(1);

		return key === order_by;
	});

	const dateFilter = validDateFilter(from as string, to as string);
	const momentFrom = moment(from as MomentInput, "DD-MM-YYYY")
		.startOf("day")
		.format();
	const momentTo = moment(to as MomentInput, "DD-MM-YYYY")
		.endOf("day")
		.format();

	const dateFrom =
		dateFilter && from ? new Date(momentFrom).toString() : undefined;
	const dateTo = dateFilter && to ? new Date(momentTo).toString() : undefined;

	const labels = !label
		? []
		: Array.isArray(label)
			? (label as string[])
			: [label];

	return {
		dateFrom,
		dateTo,
		labels,
		limit: validateLimit(limit),
		email: filter_email as string,
		memberRole: filter_member_role as string,
		name: search as string,
		orderBy: validOrder ? (order_by as string) : sorting.NEWEST,
		page: validatePage(page),
		testMode: (validMode ? mode : MODE.ALL.key) as string
	};
};

const getRequestConfig = (options: RequestOptions | {} = {}): RequestConfig => {
	const { cancelToken, config, accessToken } = options as RequestOptions;

	const headers: Headers | {} = config?.headers || {};

	(headers as Headers).Authorization = `Bearer ${
		accessToken || reduxStore.getState().persistedStorage.access_token
	}`;

	const requestConfig: RequestConfig = {
		...config,
		headers: headers as Headers
	};

	if (cancelToken) {
		requestConfig.cancelToken = cancelToken;
	}

	return requestConfig;
};

type HeaderKeys = "Content-Type" | "X-Confirm-Password";

type Header = {
	[K in HeaderKeys]: string;
};

type EmptyConfig = {
	headers: Partial<Header>;
	responseType?: string;
};

const initializeEmptyConfig = (): EmptyConfig => ({ headers: {} });

const makeRequestWithQuery = (
	method: Function,
	navigate: NavigateFunction,
	params: QueryFilters,
	queryParams: QueryParams,
	options: RequestOptions
): Promise<ResponseWithPaging<unknown> | void> =>
	new Promise((resolve, reject) => {
		method(navigate, params, queryParams, options).then(
			(response: ResponseWithPaging<unknown>) => {
				const { pagination, results } = response;
				const { page, total_items, total_pages } = pagination || {};
				const qParams = {
					...queryParams,
					pagination: { ...queryParams.pagination }
				};

				if (total_pages && page === 0 && total_pages > 0) {
					qParams.pagination.page = total_pages;
					qParams.pagination.total_items = total_items;
					qParams.pagination.totalPages = total_pages;

					makeRequestWithQuery(method, navigate, params, qParams, options).then(
						resp => resolve(resp),
						() => reject()
					);

					return;
				}

				qParams.pagination.page = page;
				qParams.pagination.total_items = total_items;
				qParams.pagination.totalPages = total_pages;

				resolve({
					pagination: qParams.pagination,
					results: results || []
				});
			},
			() => {
				reject();
			}
		);
	});

const setQueryParam = (
	key: string,
	paramValue: string | number | boolean
): { [key: string]: string | number | boolean } => {
	let value = paramValue;

	if (
		key === queryKeys.PAGE &&
		(typeof value === "number" || typeof value === "string")
	) {
		value = Number(paramValue as string) > 1 ? paramValue : 0;
	}

	return { key, value };
};

export {
	createPaginationQuery,
	createQuery,
	createRoute,
	getOffset,
	getRequestConfig,
	getResultQueryParams,
	initializeEmptyConfig,
	makeRequestWithQuery,
	setQueryParam
};
