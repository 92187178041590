import { toast } from "react-toastify";

// eslint-disable-next-line import/named
import { TFunction } from "i18next";

import { alertError } from "../api/helper";

import ToastWithList from "../components/toastWithList/ToastWithList";
import { APIError as Error } from "../types";

import { toastAlert } from "./const";

import { snakeToCamel } from "./method";

export type ConflictError = {
	data: {
		code: number;
		error: string;
	};
	status: number;
	statusText: string;
};

type MappedNames = {
	[key: string]: string;
} | null;

export type RequestValidationError = {
	data: {
		code: number;
		errors: {
			[key: string]: string[];
		};
	};
	status: number;
	statusText: string;
};

const alertConflictError = (error: Error<ConflictError>): void => {
	const { response } = error || {};

	if (response && response?.status === 409) {
		toast.error(response?.data.error);

		return;
	}

	alertError({ error });
};

const handleRequestBodyError = (
	error: Error<RequestValidationError>,
	errorKey: string,
	errorMsg: string,
	autoClose?: boolean
): void => {
	const { response } = error || {};
	if (!response) return;

	switch (response.status) {
		case 409:
		case 422:
			const { data } = response;

			if (!Object.keys(data.errors).length) return;

			toast.error(
				<ToastWithList title={errorMsg} listItems={data.errors[errorKey]} />,
				{ autoClose: autoClose ? toastAlert.AUTO_CLOSE_TIME : false }
			);
			break;
		default:
	}
};

const handleServerValidationErrors = (
	error: Error<RequestValidationError>,
	setFormError: (error: (prevFormError: Error) => Error) => void,
	mappedNames: MappedNames = null
): void => {
	const { response } = error || {};

	const errors = response && response.data && response.data.errors;

	if (!errors) return;

	Object.keys(errors).forEach(fieldName => {
		const name =
			mappedNames !== null ? mappedNames[fieldName] : snakeToCamel(fieldName);

		setFormError((prevFormError: Error) => ({
			...prevFormError,
			[name]: errors[fieldName]
		}));
	});
};

const handleServerPasswordErrors = (
	error: Error<RequestValidationError>,
	setFormError: (error: (prevFormError: Error) => Error) => void,
	t: TFunction
): void => {
	const { response } = error || {};

	const errors = response && response.data && response.data.errors;

	if (!errors) return;

	switch (response.status) {
		case 422:
			setFormError((prevFormError: Error) => ({
				...prevFormError,
				newPassword: t("ERROR.PASSWORD.IDENTICAL_WITH_CURRENT")
			}));

			break;

		default:
			handleServerValidationErrors(error, setFormError);

			break;
	}
};

export {
	alertConflictError,
	handleRequestBodyError,
	handleServerPasswordErrors,
	handleServerValidationErrors
};
