import { isCancel } from "axios";
import { type NavigateFunction } from "react-router-dom";

import { routes } from "../../router/routes";
import {
	APIError,
	CreateMemberData,
	MemberData,
	MemberParams,
	ResponseWithPaging,
	RequestOptions,
	QueryParams
} from "../../types";

import { queryKeys } from "../../utils/const";
import { alertConflictError } from "../../utils/error";
import { checkID } from "../../utils/method";
import {
	createPaginationQuery,
	createQuery,
	createRoute,
	setQueryParam
} from "../../utils/request";

import { api } from "./api";

import { crud } from "./crud";

const createMember = (
	navigate: NavigateFunction,
	params: MemberParams,
	data: CreateMemberData,
	options: RequestOptions
): Promise<MemberData | {} | APIError> => {
	const { projectID } = params;
	const route = createRoute(true, routes.PROJECTS, projectID, routes.MEMBERS);
	return new Promise((resolve, reject) => {
		api.POST<MemberData | {} | APIError>(navigate, route, data, options).then(
			response => {
				resolve(response.data || {});
			},
			error => {
				if (isCancel(error)) return reject(error);

				alertConflictError(error);
				reject(error);
			}
		);
	});
};

const deleteMember = (
	navigate: NavigateFunction,
	params: Required<MemberParams>,
	pending: boolean
): Promise<void | {} | APIError> => {
	const { memberID, projectID } = params;
	const route = createRoute(
		false,
		routes.PROJECTS,
		checkID(projectID),
		routes.MEMBERS,
		pending ? routes.INVITED : "",
		checkID(memberID)
	);

	return new Promise((resolve, reject) => {
		api.DELETE<void | {} | APIError>(navigate, route).then(
			response => {
				resolve(response.data || {});
			},
			error => {
				if (isCancel(error)) return reject(error);

				alertConflictError(error);
				reject(error);
			}
		);
	});
};

const getAllMembers = (
	navigate: NavigateFunction,
	params: MemberParams,
	queryParams: QueryParams | null,
	options: RequestOptions
): Promise<ResponseWithPaging<MemberData> | APIError> => {
	const { projectID } = params;
	const { pagination, filters, sorts } = queryParams || {};

	let route = createRoute(
		false,
		routes.PROJECTS,
		checkID(projectID),
		routes.MEMBERS,
		routes.FULL
	);

	if (pagination) {
		const { limit, page } = pagination;
		route += createPaginationQuery(limit, page);
	}

	if (filters && filters.role !== "all") {
		route += createQuery(
			setQueryParam(queryKeys.FILTER.MEMBER_ROLE, filters.role)
		);
	}

	if (filters && filters.email) {
		route += createQuery(setQueryParam(queryKeys.FILTER.EMAIL, filters.email));
	}

	if (sorts && sorts.length) {
		console.log(sorts);

		for (const sort of sorts) {
			// this will only have one iterarion,
			// the reason the loop is necessary, is to
			// get the key and value of the object separatelly.
			for (const sortType in sort) {
				route += createQuery(setQueryParam(sortType, sort[sortType]));
			}
		}
	}

	const firstQuestionMarkIndex = route.indexOf("?");
	route =
		route.substring(0, firstQuestionMarkIndex + 1) +
		route.substring(firstQuestionMarkIndex + 1).replace(/\?/g, "&");

	return crud.READ<ResponseWithPaging<MemberData>>(navigate, route, options);
};

const updateMember = (
	navigate: NavigateFunction,
	params: Required<MemberParams>,
	data: CreateMemberData,
	pending: boolean,
	options: RequestOptions
): Promise<MemberData | {} | APIError> => {
	const { memberID, projectID } = params;

	const pendingMemberRoute = createRoute(
		false,
		routes.PROJECTS,
		checkID(projectID),
		routes.MEMBERS,
		routes.INVITED,
		checkID(memberID)
	);

	const regularRoute = createRoute(
		false,
		routes.PROJECTS,
		checkID(projectID),
		routes.MEMBERS,
		checkID(memberID)
	);

	const route = pending ? pendingMemberRoute : regularRoute;

	return crud.UPDATE<MemberData>(navigate, route, data, options);
};

const inviteMembersBulk = (
	navigate: NavigateFunction,
	params: MemberParams,
	data: { invites: CreateMemberData[] },
	options: RequestOptions
): Promise<MemberData | {} | APIError> => {
	const { projectID } = params;
	const route = createRoute(
		false,
		routes.PROJECTS,
		projectID,
		routes.MEMBERS,
		routes.BULK
	);

	return new Promise((resolve, reject) => {
		api.POST<MemberData | {} | APIError>(navigate, route, data, options).then(
			response => {
				resolve(response.data || {});
			},
			error => {
				if (isCancel(error)) return reject(error);

				alertConflictError(error);
				reject(error);
			}
		);
	});
};

const memberAPI = {
	create: createMember,
	createBulk: inviteMembersBulk,
	delete: deleteMember,
	readAll: getAllMembers,
	update: updateMember
};

export default memberAPI;
